<template>
  <div class="jhyxpj">
    <div class="title">运行评价</div>
    <div class="content">
      <div style="display: flex">
        <div class="box1-item">
          <div class="biaotix">F#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE1V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE1V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE1V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE1V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">G#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE2V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE2V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE2V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE2V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">H#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE3V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE3V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE3V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE3V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
      </div>
      <div style="display: flex">
        <div class="box1-item">
          <div class="biaotix">I#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE4V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE4V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE4V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE4V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">J#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE5V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE5V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE5V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE5V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">纯水系统</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent" v-for="item in itemlist" :key="item.id">
              <div class="li_item">{{ item.name }}</div>
              <div
                class="li_value"
                v-for="(childItem, childIndex) in item.arr"
                 :key="childIndex"
                @click="
                  toCompon(
                    2,
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE7V',
                    `ANEV${item.id}${childItem.id}_FYF`,
                    `${item.title}${childItem.title}指标`
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.DEVICE7V[`ANEV${item.id}${childItem.id}`],
                    `ANEV${item.id}${childItem.id}`,
                    'DEVICE7V',
                    `ANEV${item.id}${childItem.id}_FYF`
                  )
                "
              >
                {{ infoList.DEVICE7V[`ANEV${item.id}${childItem.id}`] || "0.00" }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div class="lipjjg">/</div>
              <div class="lipjjg">/</div>
            </li>
          </ul>
        </div>
      </div>
      <ul style="display: flex">
        <li class="li_bottom_left">
          <div class="li_bottom_item">
            <div class="tupian"></div>
            <div class="valuel">
              <div
                class="valuel_item1"
                @click="toCompon(2, 'AN48', 'DEVICE7V', 'AN48_FYF', '总自控率')"
                @dblclick="Cclick(infoList.DEVICE7V.AN48, 'AN48', 'DEVICE7V', 'AN48_FYF')"
              >
                {{ infoList.DEVICE7V.AN48 || "0.00" }}
              </div>
              <div class="valuel_item2">总自控率</div>
            </div>
          </div>
          <div class="li_bottom_item">
            <div class="tupian"></div>
            <div class="valuel">
              <div
                class="valuel_item1"
                @click="toCompon(2, 'AN49', 'DEVICE7V', 'AN49_FYF', '总稳定性')"
                @dblclick="Cclick(infoList.DEVICE7V.AN49, 'AN49', 'DEVICE7V', 'AN49_FYF')"
              >
                {{ infoList.DEVICE7V.AN49 || "0.00" }}
              </div>
              <div class="valuel_item2">总稳定性</div>
            </div>
          </div>
          <div class="li_bottom_item">
            <div class="tupian"></div>
            <div class="valuel">
              <div
                class="valuel_item1"
                @click="toCompon(2, 'AN50', 'DEVICE7V', 'AN50_FYF', '总经济性')"
                @dblclick="Cclick(infoList.DEVICE7V.AN50, 'AN50', 'DEVICE7V', 'AN50_FYF')"
              >
                {{ infoList.DEVICE7V.AN50 || "0.00" }}
              </div>
              <div class="valuel_item2">总经济性</div>
            </div>
          </div>
        </li>
        <li class="li_bottom_right">
          <div class="tupian2">
            <div class="valuel_item3"></div>
            <div class="valuel_item4">总CEI</div>
          </div>
          <div class="valuelX">
            <!-- AAAAAA -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  <script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Jhindex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
      itemlist: [
        {
          id: 10,
          name: "班运行",
          arr: [
            {
            id:1,
            title:'总自控率'
          },
            {
            id:2,
            title:'稳定性'
          },
            {
            id:3,
            title:'经济性'
          },
            {
            id:4,
            title:'综合评价'
          }
        ],
          title: "班",
        },
        {
          id: 20,
          name: "日运行",
          arr: [
            {
            id:1,
            title:'总自控率'
          },
            {
            id:2,
            title:'稳定性'
          },
            {
            id:3,
            title:'经济性'
          },
            {
            id:4,
            title:'综合评价'
          }
        ],
          title: "日",
        },
        {
          id: 30,
          name: "月运行",
          arr: [
            {
            id:1,
            title:'总自控率'
          },
            {
            id:2,
            title:'稳定性'
          },
            {
            id:3,
            title:'经济性'
          },
            {
            id:4,
            title:'综合评价'
          }
        ],
          title: "月",
        },
        {
          id: 40,
          name: "自定义运行",
          arr: [
            {
            id:1,
            title:'总自控率'
          },
            {
            id:2,
            title:'稳定性'
          },
            {
            id:3,
            title:'经济性'
          },
            {
            id:4,
            title:'综合评价'
          }
        ],
          title: "自定义",
        },
      ],
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    // 阀门红绿灯
    typeFifter: (val) => {
      if (val < 5) {
        return require("@/assets/images/截止阀.png");
      } else if (val >= 5) {
        return require("@/assets/images/截止阀2.png");
      }
    },
    //釜内红绿灯
    tyFifter: (val) => {
      if (val <= 10) {
        return require("@/assets/images/关闭.png");
      } else if (val > 10) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.jhyxpj {
  // height: 96vh;

  .title {
    width: 100vw;
    text-align: center;
    height: 4vh;
    line-height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.5vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }
  .content {
    // display: flex;

    .box1-item {
      width: 31vw;
      height: 26vh;
      border: 1px solid #486164;
      margin-left: 1.8vw;
      margin-top: 3vh;
    }
    .biaotix {
      width: 31vw;
      height: 2vh;
      line-height: 2vh;
      margin-top: 1vh;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1vw;
      letter-spacing: 0vw;
      color: #ffffff;
      margin-bottom: 1vh;
    }
    .licontent {
      display: flex;
      color: #ffffff;
      height: 3vh;
      line-height: 3vh;
      .li_item {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        // border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .li_iteml {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .li_value {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
        // border-rigth: 1px solid #486164;
        cursor: pointer;
      }
    }
    .liyxpj {
      display: flex;
      color: #ffffff;
      height: 7vh;
      line-height: 7vh;
      .li_item {
        width: 6.25vw;
        height: 7vh;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        // border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .lipjjg {
        width: 12.5vw;
        text-align: center;
        font-size: 0.8vw;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
      }
    }
  }
  .li_bottom_left {
    width: 64vw;
    height: 17vh;
    margin-left: 1.8vw;
    margin-top: 3vh;
    display: flex;
    border: 1px solid #486164;
    .li_bottom_item {
      width: 21.3vw;
      height: 17vh;
      line-height: 17vh;
      display: flex;
      border-right: 1px solid #486164;

      .tupian {
        width: 4vw;
        height: 8vh;
        background-image: url("~@/assets/images/瞬时总自控率.png");
        background-size: 100% 100%;
        // border: 1px solid #ffffff;
        margin-top: 5vh;
        margin-left: 7vw;
      }
      .valuel {
        margin-left: 1vw;
        height: 17vh;
        line-height: 17vh;
        box-sizing: border-box;
        padding-top: 4vh;cursor: pointer;
        .valuel_item1 {
          width: 5vw;
          line-height: 4vh;
          color: #00e4ff;
          font-size: 1.1vw;
        }
        .valuel_item2 {
          width: 5vw;
          line-height: 4vh;
          font-size: 0.8vw;
          color: #8aeaff;
        }
      }
    }
  }
  .li_bottom_right {
    width: 31vw;
    height: 17vh;
    margin-left: 1.8vw;
    margin-top: 3vh;
    display: flex;
    border: 1px solid #486164;
    .tupian2 {
      width: 4vw;
      height: 8vh;
      background-image: url("~@/assets/images/底座.png");
      background-size: 100% 100%;
      // border: 1px solid #ffffff;
      margin-top: 5vh;
      margin-left: 10vw;
      box-sizing: border-box;
      padding-left: 0.5vw;
    }
    .valuel_item3 {
      width: 5vw;
      line-height: 4vh;
      color: #00e4ff;
      font-size: 1.1vw;
    }
    .valuel_item4 {
      width: 5vw;
      line-height: 4vh;
      font-size: 0.8vw;
      color: #8aeaff;
    }
    .valuelX {
      width: 5vw;
      color: #ffffff;
      line-height: 17vh;
      margin-left: 1vw;
    }
  }
}
</style>
  
  
  